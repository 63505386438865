<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      class="white--text elevation-2 pa-2"
      app
      mobile-break-point="960"
      color="white"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="title"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <span style="flex-basis: 50%">Sections</span>
            <v-btn
              v-if="user.groupId <= adminId"
              icon
              color="indigo"
              @click="alertDialog = true"
              ><v-icon size="20" class="fa-solid fa-bell"></v-icon
            ></v-btn>
            <v-btn
              v-if="user.groupId <= adminId"
              icon
              color="indigo"
              @click="settingDialog = true"
              ><v-icon size="20" class="fa-solid fa-sliders-h"></v-icon
            ></v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-if="user.groupId == 0"
          :to="'/' + this.$i18n.locale + '/admin'"
          link
          exact
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px"
              ><v-icon size="20" color="indigo" left
                >fa-solid fa-gauge</v-icon
              >
              Dashboard</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="user.groupId === adminId"
          :to="'/' + this.$i18n.locale + '/admin/manage-address'"
          link
          exact
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px"
              ><v-icon size="20" color="indigo" left
                >fa-solid fa-map-marker</v-icon
              >
              Address Management</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="user.groupId <= adminId"
          :to="'/' + this.$i18n.locale + '/admin/manage-shops'"
          link
          exact
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px"
              ><v-icon size="20" color="indigo" left>fa-solid fa-store</v-icon
              >Manage Shops</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-if="user.groupId <= adminId">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size:15px"
                ><v-icon size="20" color="indigo" left>fa-solid fa-map</v-icon
                >Manage Rates</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="user.groupId <= adminId"
            :to="'/' + this.$i18n.locale + '/admin/zones'"
            link
          >
            <v-list-item-content>
              <v-list-item-title style="font-size:15px"
                ><v-icon size="20" color="indigo" left
                  >fa-solid fa-map-marked-alt</v-icon
                >Zones</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="user.groupId <= adminId"
            :to="'/' + this.$i18n.locale + '/admin/side-rates'"
            link
          >
            <v-list-item-content>
              <v-list-item-title style="font-size:15px"
                ><v-icon size="20" color="indigo" left
                  >fa-solid fa-percentage</v-icon
                >Enovi Shipping Rates</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="user.groupId <= adminId"
            :to="'/' + this.$i18n.locale + '/admin/rates'"
            link
          >
            <v-list-item-content>
              <v-list-item-title style="font-size:15px"
                ><v-icon size="20" color="indigo" left
                  >fa-solid fa-percentage</v-icon
                >Shipping Rates</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="font-size:15px"
                ><v-icon size="20" color="indigo" left>fa-solid fa-box</v-icon
                >Packages</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <div style="padding-left:17px" class="grey lighten-5">
            <v-list-item
              v-if="user.groupId <= adminId"
              :to="'/' + this.$i18n.locale + '/admin/addpackage/parcel'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px"
                  ><v-icon size="20" color="indigo" left
                    >fa-solid fa-plus-circle</v-icon
                  >Add Parcel</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="user.groupId <= adminId"
              :to="'/' + this.$i18n.locale + '/admin/addpackage/mail'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px"
                  ><v-icon size="20" color="indigo" left
                    >fa-solid fa-plus-circle</v-icon
                  >Add Mail</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="'/' + this.$i18n.locale + '/admin/manage-packages'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px"
                  ><v-icon size="20" color="indigo" left
                    >fa-solid fa-clipboard-list</v-icon
                  >Manage Packages</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="'/' + this.$i18n.locale + '/admin/manage-assemble'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px">
                  <v-icon size="20" color="indigo" left
                    >fa-solid fa-box-open</v-icon
                  >

                  Manage Assembled Packages
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="'/' + this.$i18n.locale + '/admin/manage-expired'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px">
                  <v-icon size="20" color="indigo" left
                    >fa-solid fa-calendar-times</v-icon
                  >

                  Expired Packages
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="'/' + this.$i18n.locale + '/admin/packages/shipped'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px">
                  <v-icon size="20" color="indigo" left
                    >fa-solid fa-dolly</v-icon
                  >

                  Shipped Packages
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="'/' + this.$i18n.locale + '/admin/deleted-packages'"
              link
            >
              <v-list-item-content>
                <v-list-item-title style="font-size:15px"
                  ><v-icon size="20" color="indigo" left
                    >fa-solid fa-trash</v-icon
                  >Manage Trash</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>

        <v-list-item :to="'/' + this.$i18n.locale + '/admin/manageusers'" link>
          <v-list-item-content>
            <v-list-item-title style="font-size:15px"
              ><v-icon size="20" color="indigo" left>fa-solid fa-users</v-icon
              >Manage Users</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/manage-alerts'"
          link
          exact
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px"
              ><v-icon size="20" color="indigo" left>fa-solid fa-bell</v-icon>
              Manage Alerts</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/images'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fa-solid fa-camera</v-icon>

              Image Requests

              <div
                v-if="this.imagesRequestCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.imagesRequestCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/assemble'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-box-open</v-icon
              >

              Manage Assemble

              <div
                v-if="this.assembleRequestsCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.assembleRequestsCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/disassemble'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-boxes-stacked</v-icon
              >

              Manage Disassemble

              <div
                v-if="this.disassembleCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.disassembleCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/shipping-requests'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-check-circle</v-icon
              >

              Approval Requests

              <div
                v-if="this.approvalCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.approvalCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/bitcoin'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fab fa-bitcoin</v-icon>

              Bitcoin Payments

              <div
                v-if="this.bitcoinCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.bitcoinCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/payment/offline'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-cash-register</v-icon
              >

              Offline Payments

              <div
                v-if="this.bitcoinCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.offlineCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/shipping'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-shipping-fast</v-icon
              >

              Shipping Requests

              <div
                v-if="this.shippingCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.shippingCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/packages/shipped'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fa-solid fa-dolly</v-icon>

              Shipped Packages
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/admin/support'" link>
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-question-circle</v-icon
              >

              Manage Support

              <div
                v-if="this.supportCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.supportCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/admin/transactions'" link>
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fab fa-paypal</v-icon>

              Transactions
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/admin/changepass'" link>
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fa-solid fa-lock</v-icon>

              Change Password
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left
                >fa-solid fa-sign-out-alt</v-icon
              >

              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar elevation="1" class="grey lighten-5">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
        ><v-icon color="black" class="ma-3"
          >fa-solid fa-bars</v-icon
        ></v-app-bar-nav-icon
      >

      <v-toolbar-title>{{$route.name}}</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div>
      <router-view></router-view>
    </div>
    <v-dialog v-model="alertDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Broadcast Alert</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="['success', 'info', 'warning', 'error']"
                  label="Type"
                  v-model="alertType"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="alertTextEn"
                  label="Alert text EN"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="alertTextAr"
                  label="Alert text AR"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="alertTextFr"
                  label="Alert text FR"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="alertDialog = false">Close</v-btn>
          <v-btn color="success" @click="handleAlert">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="settingDialog" max-width="500">
      <v-card class="mx-auto">
        <v-card-title style="display: flex; justify-content: space-between;">
          <span>Settings</span>
          <v-btn icon color="error" @click="settingDialog = false"
            ><v-icon
              color="error"
              size="30"
              class="fa-solid fa-times-circle"
            ></v-icon
          ></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>
              <v-icon
                size="20"
                style="color: indigo;"
                class="fa-solid fa-images mx-2"
              ></v-icon>
              <span>Image Requests</span>
            </v-list-item-title>

            <v-list-item-subtitle
              style="display: flex; justify-content: center;"
              class="text-right"
            >
              <v-switch
                @change="updateSetting"
                v-model="setImgReq"
                :label="`${setImgReq.toString() === 'true' ? 'ON' : 'OFF'}`"
              ></v-switch>
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-icon
                size="20"
                style="color: indigo;"
                class="fa-solid fa-box mx-2"
              ></v-icon>
              <span>Package Descriptions</span>
            </v-list-item-title>

            <v-list-item-subtitle
              style="display: flex; justify-content: center;"
              class="text-right"
            >
            <v-btn @click="settingDialog = false" to="/en/admin/manage-descriptions" color="primary">Manage</v-btn>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";

axios.defaults.headers.common["Authorization"] = `bearer ${auth.adminToken()}`;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem("adminToken");
      window.location = "/en/panel";
    } else {
      return Promise.reject(error);
    }
  }
);
export default {
  name: "AdminPanel",
  data() {
    return {
      drawer: true,
      imagesRequestCount: 0,
      assembleRequestsCount: 0,
      shippingCount: 0,
      supportCount: 0,
      approvalCount: 0,
      bitcoinCount: 0,
      offlineCount: 0,
      disassembleCount: 0,
      alertDialog: false,
      alertType: "",
      alertTextAr: "",
      alertTextFr: "",
      alertTextEn: "",
      message: "",
      snackbar: false,
      setImgReq: true,
      settingDialog: false,
      color: "",
      adminId: 0,
      supportId: 2,
      user: auth.admin(),
    };
  },

  sockets: {
    loadNotifications(data) {
      //console.log(data.notifications)
      // Fired when the socket connects.
      this.imagesRequestCount = data.notifications.imageRequests;
      this.assembleRequestsCount = data.notifications.assembleRequests;
      this.shippingCount = data.notifications.shippingPackages;
      this.supportCount = data.notifications.tickets;
      this.approvalCount = data.notifications.packages;
      this.bitcoinCount = data.notifications.transactions;
      this.offlineCount = data.notifications.offlineTransactions;
      this.disassembleCount = data.notifications.disassembleRequests;
    },

    newSupportTicket(data) {
      this.supportCount = data.count;
    },

    newBitcoinPayment(data) {
      this.bitcoinCount = data.count;
    },
    newOfflinePayment(data) {
      this.offlineCount = data.count;
    },

    newImageRequest(data) {
      this.imagesRequestCount = data.count;
    },

    newAssembleRequest(data) {
      this.assembleRequestsCount = data.count;
    },

    newPackageApproveRequest(data) {
      this.approvalCount = data.count;
    },

    newShipping(data) {
      this.shippingCount = data.count;
    },
  },

  mounted() {
    // axios.get('/api/packages/images/count').then((res)=>{
    //     this.imagesRequestCount = res.data.count
    // })

    // axios.get('/api/packages/assemble/count').then((res)=>{
    //     this.assembleRequestsCount = res.data.count
    // })

    // axios.get('/api/packages/shipping/count').then((res)=>{
    //     this.shippingCount = res.data.count
    // })

    // axios.get('/api/admins/support/count').then(res=>{
    //   this.supportCount = res.data.count
    // })

    // axios.get('/api/packages/approval/count').then(res=>{
    //   this.approvalCount = res.data.count
    // })
    axios
      .get("/api/admins/setting")
      .then((res) => {
        this.setImgReq = res.data.setting.imageRequest;
      })
      .catch((err) => {
        console.log(err.code);
      });

    // Send the "getNotifications" event to the server.
    this.$socket.emit("adminJoin",{
      groupId: this.user.groupId,
      warehouse: this.user.warehouse
    });
  },
  methods: {
    logout() {
      auth.adminLogout();
      this.$emit("authChange");
      this.$router.push("/en/panel");
    },

    updateSetting() {
      const setting = { imageRequest: this.setImgReq };
      axios
        .post("api/admins/setting", { setting })
        .then((res) => {
          if (!res.data.error) {
            this.message = res.data.message;
            this.snackbar = true;
            this.color = "success";
          } else {
            this.message = "An error happened please try again";
            this.snackbar = true;
            this.color = "error";
          }
        })
        .catch((err) => {
          this.message = "An error happened please try again";
          this.snackbar = true;
          this.color = "error";

          console.log(err);
        });
    },

    handleAlert() {
      const type = this.alertType;
      const textEn = this.alertTextEn;
      const textAr = this.alertTextAr;
      const textFr = this.alertTextFr;

      axios
        .post("api/admins/alert/create", {
          type,
          textEn,
          textAr,
          textFr
        })
        .then((res) => {
          if (!res.data.error) {
            this.message = res.data.message;
            this.snackbar = true;
            this.color = "success";

            this.alertType = "";
            this.alertTextEn = "";
            this.alertTextAr = "";
            this.alertTextFr = "";
            this.alertDialog = false;
          } else {
            this.message = "An error happened please try again";
            this.snackbar = true;
            this.color = "error";
          }
        })
        .catch((err) => {
          this.message = "An error happened please try again";
          this.snackbar = true;
          this.color = "error";

          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.counter-badge {
  padding: 5px;
  display: inline-block;
  font-size: 14px;
  height: 23px;
  width: 23px;
  text-align: center;
  padding-bottom: 10px !important;
}
</style>
